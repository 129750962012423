.cms_item.pageTiles_small2,
.cms_item.pageTiles_small3 {
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 1600px;
  }
  @media screen and (min-width: 2500px) {
    max-width: 2000px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 0;
  }
}
.htmlPanel {
  padding-inline: 1rem;
  @media screen and (min-width: 1200px) {
    //max-width: 1200px;
  }
  @media screen and (min-width: 1600px) {
    //max-width: 1400px;
  }
  @media screen and (min-width: 1920px) {
    //max-width: 1600px;
  }
  @media screen and (min-width: 2500px) {
    //max-width: 2000px;
  }
  @media screen and (max-width: 576px) {
    padding-inline: 0.3rem;
  }
}
.contentPanel .container {
  max-width: unset;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 1600px;
  }
  @media screen and (min-width: 2500px) {
    max-width: 2000px;
  }
}
.pageTile_small3.pageTile_small3_item {
  @media screen and (min-width: 1200px) {
    padding: 0.5rem;
  }
  @media screen and (min-width: 1600px) {
    padding: 0.5rem 1rem;
  }
}
.pageTile_small3.pageTile_small3_item .pageTile_small3__title {
  @media screen and (min-width: 576px) {
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 576px) {
    line-height: unset;
  }
}
.pageTiles_small3_warpper {
  @media screen and (max-width: 576px) {
    padding: 0 0 0 0.8rem;
  }
}
.pageTiles_small3_warpper .pageTile_small3_item .pageTile_small3_link {
  @media screen and (max-width: 576px) {
    border-radius: 8px;
  }
}
.pageTiles_small3_warpper .pageTile_small3_link .pageTile_small3__bg {
  @media screen and (max-width: 576px) {
    padding-top: 90%;
  }
}
.pageTiles_small3_warpper .pageTile_small3_item {
  @media screen and (max-width: 576px) {
    padding: 0rem 0.8rem 0.8rem 0;
  }
}
.pageTiles_small3_warpper .pageTile_small3_item .pageTile_small3__title {
  @media screen and (max-width: 576px) {
    margin: 0.5rem 0.8rem 1rem;
    font-size: 0.9rem;
    height: 1.6rem;
  }
}
