.card__tiles {
  margin: 0 0.8rem 3rem;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto 3rem;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1400px;
  }
  @media screen and (min-width: 1920px) {
    max-width: 1600px;
  }
  @media screen and (min-width: 2500px) {
    max-width: 2000px;
  }
}
.card__tile__con {
  border-radius: 8px;
  @media screen and (min-width: 576px) {
    border-radius: 20px;
  }
}
.card__tile__link .card__tile__con .card__tile__detail {
  padding: 1rem;
  @media screen and (min-width: 576px) {
    padding: 2rem;
  }
}
