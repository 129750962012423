.productViewPanel .container {
  margin-top: 35px;
  @media screen and (min-width: 576px) {
    margin-top: 56px;
  }
}
.productView .productView__title__container {
  @media screen and (max-width: 576px) {
    margin: 1.5rem 0 1rem;
  }
}
.productView #productView__each__price__id {
  @media screen and (max-width: 576px) {
    padding-left: 0;
    align-items: unset;
  }
}
.productView .dollar_icon {
  font-size: 1.3rem;
  padding: 0.2rem 0.6rem;
  border: 2px solid rgb(67, 176, 42);
  @media screen and (min-width: 576px) {
    font-size: 1.3rem;
    padding: 0.2rem 0.7rem;
    border: 4px solid rgb(67, 176, 42);
  }
  @media screen and (min-width: 1200px){
    font-size: 2rem;
    padding: 0.2rem 0.7rem;
    border: 4px solid rgb(67, 176, 42);
  }
}
.productView .price_num {
  font-size: 1.2rem;
  margin-left: 0.4rem;
  @media screen and (min-width: 576px) {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 2rem;
    margin-left: 0.5rem;
  }

}
.productView .inc_gst {
  font-size: 0.6rem;
  @media screen and (min-width: 576px) {
    font-size: 0.7rem;
  }
}
.productView .each_text {
  font-size: 0.7rem;
  @media screen and (min-width: 576px) {
    font-size: 1.1rem;
  }
}
.productView .contiune__button_con {
  @media screen and (min-width: 576px) {
    text-align: right;
  }
}
.productView .contiune__button_con .contiune__button {
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: 2rem;
  }
}

.cms_item.ecm .p_f_warpper {
  @media screen and (max-width: 576px) {
    display: block;
  }
}
.cms_item.ecm .p_f_warpper .filter__warpper {
  @media screen and (min-width: 576px) {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 2rem;
  }
  @media screen and (min-width: 2500px) {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 2rem;
    padding-top: 1rem;
  }
}
.cms_item.ecm .p_f_warpper .tile__warpper {
  @media screen and (min-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media screen and (min-width: 2500px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.all_cate_des {
  display: none;
  @media screen and (min-width: 576px) {
    display: block;
  }
}
.all_cate_mob {
  display: block;
  @media screen and (min-width: 576px) {
    display: none;
  }
}
.all_cate_mob_btn {
  width: 100%;
  background-color: #43b02a;
  border-color: #43b02a;
  font-weight: 600;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
}
.all_cate_item_con {
  @media screen and (max-width: 576px) {
    padding-left: 1rem;
  }
}
.all_cate_btn_con,
.all_cate_item_con {
  @media screen and (min-width: 2500px) {
    margin-left: 1rem;
  }
}
.productView__hero__container img {
  @media screen and (min-width: 2500px) {
    max-width: 85%;
  }
}
.tile__link__detail__con{
  padding: 0rem 1.1rem 0.8rem;
  @media screen and (min-width: 2500px) {
    padding: 0rem 1.4rem 1rem;
  }
}
.tile_link {
  //padding: 1.1rem 1.1rem 0.8rem;
  // @media screen and (min-width: 2500px) {
  //   padding: 1.4rem 1.4rem 1rem;
  // }
}
.tile_link_name {
  font-size: 0.8rem;
  @media screen and (min-width: 2500px) {
    font-size: 1rem;
  }
}
.tile_link_des {
  font-size: 0.8rem;
  margin-bottom: 0.6rem;
  @media screen and (min-width: 2500px) {
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
}
.tile_link_price {
  font-size: 0.8rem;
  @media screen and (min-width: 2500px) {
    font-size: 1rem;
  }
}

.productView{
  margin-top: 7rem;
}

.productView__each__price__id__2{
  display: none;
}