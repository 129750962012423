// overriding bootstrap variables in _boostrap-override.scss
@import url("https://use.typekit.net/kin7owt.css");

$family-base: "proxima-nova", sans-serif;
$family-header: "Montserrat", sans-serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-light;
$weight-semibold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
// $color-green:   #28a745;
$color-green: #78be21;

// more colors
$color-gold: #faa222;
$color-grey: #212121;
$color-dark-gray: #252525;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;
$color-dark-green: #548417;

// site colors
$primary-color: $color-grey;
$font-color: $color-grey;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $color-green;
// $link-hover-color: darken($primary-color, 10);
$link-hover-color: $color-dark-green;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  mobile: 44px,
  small-device: 56px,
  tablet: 88px,
  desktop: 88px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
