.main-content {
  //padding-top: map-get($top-bar-height, mobile);
  //overflow: hidden;
  @include media-breakpoint-up(lg) {
    //padding-top: map-get($top-bar-height, desktop);
    padding-top: 4rem;
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem;
    border-radius: 0px;
    background-color: $link-color;
    border-color: $link-color;
    color: white;
    font-weight: $weight-bold;
    &:hover {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
    }
  }
}
