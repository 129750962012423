.container {
  max-width: 1100px;
}

.pageTiles_big {
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
}
.cms_item.pageTiles_big .tile_big {
  flex: 0 0 50%;
  max-width: 50%;
  @media screen and (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.tile_big .pageTile__content.tile__content {
  position: relative;
}
.tile_big .pageTile_details {
  padding: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
}
.tile_big .pageTile_details_content {
  width: 80%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.tiles .tile_big .tile__bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tile_big .pageTile__content.tile__content:hover .pageTile__title_bg,
.tile_big .pageTile__content.tile__content:active .pageTile__title_bg {
  display: none;
}

.tile_big .pageTile__content.tile__content:hover .pageTile_details,
.tile_big .pageTile__content.tile__content:active .pageTile_details {
  opacity: 1;
  transform: translateY(0);
}
.pageTiles_big .tile_big .tile__bg,
.tile_big .pageTile_details {
  width: 100%;
  height: 100%;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 340px;
    padding: 16px;
  }
}
.tile_big .pageTile__content:hover .pageTile_details,
.tile_big .pageTile__content:focus .pageTile_details {
  background-color: rgba(17, 25, 33, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.tile_big .pageTile__content:hover .pageTile__title,
.tile_big .pageTile__content:hover .pageTile_desc,
.tile_big .pageTile__content:active .pageTile__title,
.tile_big .pageTile__content:active .pageTile_desc,
.tile_big .pageTile__content:focus .pageTile__title,
.tile_big .pageTile__content:focus .pageTile_desc {
  color: #fff;
}
.tile_big .tile_button {
  color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  background: transparent;
  padding: 0.1rem 1rem;
  font-weight: 700;
  font-size: 0.7rem;
  display: inline-block;
  &:focus,
  &:hover,
  &:active {
    background: #fff;
    color: #000;
    text-decoration: none;
  }
}
.tile_big .pageTile__title {
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    margin: 1.3rem 0;
  }
}
.tile_big .pageTile_desc {
  line-height: 1.2;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  font-family: $family-base;
  @media screen and (max-width: 576px) {
    line-height: 1.4;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}
.tile_big .pageTile__title_bg {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  padding: 1.5rem 1.4rem 0.4rem;
  font-size: 1.3rem;
  line-height: 1.2;
  color: white;
  height: 30%;
  font-family: $family-base;
  font-weight: $weight-bold;
  // background: linear-gradient(180deg, transparent 0, #000);
  background: linear-gradient(180deg, transparent 22%, #000 127%);
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
