@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";
@import "./modules/accordingPanel/";
@import "./modules/rotatingReviewPanel";
@import "./modules/cardTiles";
@import "./modules/productView";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

// react-image-gallery
@import "~react-image-gallery/styles/css/image-gallery.css";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }

  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.contactForm .btn {
  border-radius: 3px;
}

// react-image-gallery
.photoSlide .image-gallery-right-nav,
.photoSlide .image-gallery-left-nav {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

.photoSlide .image-gallery-left-nav .image-gallery-svg,
.photoSlide .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 51px;

  @media screen and (max-width: 576px) {
    width: 13px;
    height: 32px;
  }
}

.photoSlide .image-gallery-slide .image-gallery-image {
  object-fit: cover;
  aspect-ratio: 1.7;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 1.7;
}

.photoSlide .fslightbox-toolbar-button.fslightbox-flex-centered:first-child {
  display: none;
}

:not(:root):fullscreen::backdrop {
  height: 100%;
}

.fslightbox-open .cms_item.photoSlide {
  height: 100vh;
}

.fslightbox-open .cms_item.photoSlide .fslightbox-container {
  top: 81px;

  @media screen and (max-width: 576px) {
    top: 44px;
  }
}

.fslightbox-open .cms_item.photoSlide .fslightbox-container .fslightbox-source {
  transform: translateY(-57px);
}

.sliderAwardPanel button.slick-arrow.slick-prev,
.sliderAwardPanel button.slick-arrow.slick-next {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

.sliderAwardPanel .slick-list {
  @media screen and (max-width: 576px) {
    margin: 0 30px;
  }
}

.sliderAwardPanel .slick-list .sliderImage {
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.testimonials .rotatingReviewItems .slick-prev::before,
.testimonials .rotatingReviewItems .slick-next::before {
  color: white;
}

.file-preparation-tips .container {
  max-width: 100%;
}

h3>.h3-bg {
  background-color: blue;
  padding: 1rem 2rem;
}

div.portal__background {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

div.portal__popupcontainer {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 100%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  max-width: 540px;
  background-color: white;
  border-radius: 0.25rem;

  &.designservicesenquiry {
    max-width: 720px;
    //height: 95%;
    top: 50%;
    overflow: auto;
    max-height: 95%;
  }

  &.stageloading {
    max-width: 300px;
    max-height: fit-content;
    //top: 50%;
  }

  &.designreview {
    max-width: 860px;
  }
}

.select__option.error {
  margin: 0;
}

.subOptions__title.error {
  margin: 0;
}

.popup__almost__finished {
  padding-top: 1rem;
  padding-bottom: 1rem;

  button.btn {
    //max-width: 195px;
    //width: 100%;
    background-color: white;
    color: black;

    &:nth-of-type(2) {
      float: right;
    }
  }
}

.filepond--drop-label.filepond--drop-label label {
  width: 100%;
  padding: 0 !important;

  span.filepond--label-action {
    display: block;
    text-decoration: none;
    background-color: black;
    color: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 20px;
  }
}

.filepond--panel-root {
  background-color: transparent !important;
}

a.filepond--credits {
  display: none;
}

.cms_item.contentPanel.container_1540 .container {
  // max-width: 1540px;
  // padding-left: 2rem;
  // padding-right: 2rem;
}

.top__bar__con {
  // @media screen and (min-width: 1200px) {
  //   max-width: 1200px;
  // }
  // @media screen and (min-width: 1600px) {
  //   max-width: 1400px;
  // }
  // @media screen and (min-width: 1920px) {
  //   max-width: 1600px;
  // }
  // @media screen and (min-width: 2500px) {
  //   max-width: 2000px;
  // }
}

// .top__bar__con + .cart__info__con {
//   @media screen and (max-width: 576px) {
//     right: -6px;
//   }
// }
.top__bar__con+.cart__info__con svg {
  @media screen and (max-width: 576px) {
    width: 35px;
    height: 35px;
  }
}

.top_bar_sub {
  transition: opacity ease 0.3s;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

.top_bar_main:hover .top_bar_sub {
  opacity: 1;
  height: auto;
  background-color: #1a1a1a;
  width: 110%;
  position: absolute;
  left: 0;
  line-height: 2;

  @media screen and (min-width: 2500px) {
    //width: 10%;
  }
}

.top_bar_sub_products {
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 900px;
  margin: 1rem auto;
  height: 100%;
  justify-content: space-evenly;
  text-align: left;
  padding: 0 1rem;
}

.top_bar_main svg {
  transition: all 0.2s ease-out;
}

.top_bar_main:hover svg {
  transform: rotate(180deg);
  transition: all 0.2s ease-out;
}


.htmlPanel {
  padding-inline: 1rem;
  @media screen and (min-width: 1200px) {
    //max-width: 1200px;
  }

  @media screen and (min-width: 1600px) {
    //max-width: 1400px;
  }

  @media screen and (min-width: 1920px) {
    //max-width: 1600px;
  }

  @media screen and (min-width: 2500px) {
    //max-width: 2000px;
  }

  @media screen and (max-width: 576px) {
    padding-inline: 0.3rem;
  }
}